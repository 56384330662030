<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="clearClose" append-to-body
    custom-class="add-regulation-dialog"
    destroy-on-close
    title="报警详情"
    width="868px"
  >
    <div v-if="currentVehicle?.manufactorType == 'HK'">
      <div class="title-box" style="font-weight: bold;font-size: 16px;color: #F64A2D;">
        <img src="../../../assets/images/realTimePositioning/sb-xq-n.png" alt="" style="width: 20px;height:20px;margin-right:8px;" srcset="">
        {{ informationData.alarmName }}
      </div>
      <div class="baise-yl-box">
        <el-descriptions
          class="margin-top"
          :column="2"
          labelClassName="policy-label-style"
          contentClassName="policy-label-style"
        >
          <el-descriptions-item>
            <template slot="label">
              触发源
            </template>
            <div>
              {{ informationData.trigger || '--' }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item v-for="item,index in informationData.sbAlarmList || []" :key="index">
            <template slot="label">
              {{ item.name }}
            </template>
            <div>
              {{ item.begin || '--' }}
            </div>
            <!-- <div v-if="item.key == 'alarmAddress'">
              <AddressText
                  :autoScroll="false"
                  :lng="informationData.lon"
                  :lat="informationData.lat"
                />
            </div>
            <div v-else>{{ informationData[item.key] || informationData[item.key2] || "--" }}</div> -->
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div v-else>
      <div class="title-box">
        {{ informationData.alarmName }}
        <div
          class="title-box-level"
          :class="['title-box-level' + informationData.level]"
        >
          {{
            informationData.level == 3
              ? "高"
              : informationData.level == 2
              ? "中"
              : "低"
          }}
        </div>
      </div>
      <div class="baise-yl-box">
        <el-descriptions
          class="margin-top"
          :column="2"
          labelClassName="policy-label-style"
          contentClassName="policy-label-style"
        >
          <el-descriptions-item v-for="item in basicInformation" :key="item.key">
            <template slot="label">
              {{ item.label }}
            </template>
            <div v-if="item.key == 'alarmAddress'">
              <AddressText
                  :autoScroll="false"
                  :lng="informationData.lon"
                  :lat="informationData.lat"
                />
            </div>
            <div v-else>{{ informationData[item.key] || informationData[item.key2] || "--" }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-table
          :data="informationData.sbAlarmList || []"
          stripe border
          style="width: 100%"
        >
          <!-- <el-table-column
              prop="statusStr"
              label="工作状态"
              width="180">
              <template slot-scope="scope">
                <div class="hour-content-status" :class="[ (currentVehicle.isCommercialVehicles && scope.row.status == 2) ? 'hour-content-status2' : (currentVehicle.isCommercialVehicles && scope.row.status == 4 ? 'hour-content-status0'  : 'hour-content-status' + scope.row.status) ]">{{ getStateLabel(scope.row.status) }}</div>
              </template>
            </el-table-column> -->
          <el-table-column prop="name" label="报警起止"></el-table-column>
          <el-table-column prop="begin" label="报警开始"></el-table-column>
          <el-table-column prop="end" label="报警结束"></el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getPCZKAlarmDetail } from "@/api/vehicleApi";
import AddressText from "@/components/AddressText";
export default {
  name: "alarmDetailsDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    isCommercialVehicles: {
      type: Boolean,
      default: true,
    },
    editDetail: {
      type: Object,
      default: null,
    },
    currentVehicle: {
      type: Object,
      default: () => {},
    },
  },
  components: { AddressText },
  data() {
    return {
      dialogVisible: false,
      basicInformation: [
        {
          label: "障碍物类型",
          key: "obstacleType",
        },
        {
          label: "持续",
          key: "duration",
        },
        {
          label: "触发源",
          key: "trigger",
        },
        {
          label: "告警地址",
          key: "alarmAddress",
          key2: "address",
        },
      ],
      informationData: {},
    };
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.getDetails();
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  mounted() {},
  methods: {
    reset() {
      this.clearClose();
    },
    confirm() {
      this.dialogVisible = false;
    },
    clearClose() {
      this.dialogVisible = false;
    },
    getDetails() {
      getPCZKAlarmDetail({
        alarmId: this.editDetail.alarmId || this.editDetail.id,
        day: (this.editDetail.timeBegin || this.editDetail.devTime || this.editDetail.beginTime).split(" ")[0],
        flag: this.isCommercialVehicles ? 1 : ''
      }).then((res) => {
        console.log(res);
        this.informationData = res.obj || {};
      });
    },
  },
};
</script>

<style lang="scss">
.add-regulation-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    border-bottom: 1px solid #f6f8f9;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .title-box {
    display: flex;
    align-items: center;
    padding: 24px 0;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    &-level {
      width: 43px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      margin-left: 16px;
      border-radius: 20px;
    }
    &-level1 {
      background: #409eff;
    }
    &-level2 {
      background: #fda835;
    }
    &-level3 {
      background: #f64a2d;
    }
  }
}
</style>
<style lang="scss" scoped>
.baise-yl-box {
  ::v-deep .el-descriptions {
    .el-descriptions-item {
      padding-bottom: 24px;
      font-size: 14px;
      color: #333333;
      .el-descriptions-item__label {
        font-size: 14px;
        color: #999;
      }
    }
  }
  ::v-deep .el-table {
    .el-table__header-wrapper {
      background-color: #F2F7FD;
      .el-table__cell {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        border-bottom: none;
        background-color: transparent;
      }
      tr {
        background-color: transparent;
      }
    }
    .el-table__row {
      .el-table__cell {
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
</style>
